.scheduled-call-entry-list {
  display: flex;
  align-items: center;
  position: relative;
}

.call-entry-list .pointing.icon .setting.icon {
  position: relative;
}

.call-request-pick-button {
  margin-top: 0em;
  padding: 0.7em 1.5em;
  line-height: 1em;
}

.intake-and-subchannel {
}
