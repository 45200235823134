/**/

.portal .channel-groups {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1.25rem;
  margin-bottom: 4rem;
}

.portal .channel-groups.is-short {
  justify-content: space-around;
  text-align: center;
}

.portal .ui.header.channel-groups-header {
  font-family: "Avenir Next";
  font-size: 24px;
}

.portal .channel-group {
  display: flex;
  flex-direction: column;
  width: 313px;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

/* Col1 */
.portal .channel-group:nth-child(3n + 1) {
}
/* Col2 */
.portal .channel-group:nth-child(3n + 2) {
}
/* Col3 */
.portal .channel-group:nth-child(3n + 3) {
}

.portal .channel-group-title {
  font-size: 16px !important;
  font-weight: 600;
  font-family: "Avenir Next";
  margin-bottom: 0.5rem;
}

.portal .channel-group-list {
  display: flex;
  flex-direction: column;
  margin-top: 2rem 0rem;
}

.portal .channel-group-list-item.item {
  font-size: 16px;
  font-family: "Avenir Next";
  color: #3b404d;
  display: flex;
  flex-direction: row;
  padding: 0.25rem 0;
}

.portal .channel-group-list-item.item span {
  color: #7383b9;
}

.portal .channel-group-list-item.item:hover span {
  color: #4286f4;
}

.portal .channel-group .image-billboard {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #cccccc;
  display: flex;
  flex-direction: row;
  height: 150px;
  min-width: 100%;
}

@media (max-width: 980px) {
  .portal .channel-groups-header {
    text-align: center;
  }

  .portal .channel-groups {
    margin: 1rem 2rem 1rem 2rem;
  }
  .portal .channel-group {
    align-items: center;
    text-align: center;
    width: 300px;
  }

  .portal .channel-group-list-item.item {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .portal .channel-group {
    width: 100%;
  }

  .portal .channel-groups {
    flex-wrap: nowrap;
    flex-direction: column;
    align-self: center;
  }

  .portal .channel-group-list {
    padding-right: 0rem;
  }
}
