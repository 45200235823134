.article-link {
  min-height: 430;
}

.article-link .content {
  display: flex;
  flex-direction: column;
}

.article-link .placeholder .paragraph {
  background-image: url(/wireframe/paragraph.png);
  background-position: top right;
  height: 10em;
  margin: 1em 0;
}
.article-link .placeholder .short.paragraph {
  background-image: url(/wireframe/short-paragraph.png);
  height: 1em;
  margin: 0 0 1em;
}

.article-link .site {
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8em;
  margin-bottom: 1em;
}
.article-link .site .icon {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}
.article-link .site .name {
  color: #000;
  line-height: 16px;
}
.article-link .site .author {
  line-height: 16px;
}

.article-link .thumbnail {
  height: 126px;
  background-position: center center;
}
.article-link .thumbnail.portrait {
  background-size: contain;
}
.article-link .thumbnail.landscape {
  background-size: cover;
}

.article-link .description {
  flex: 1;
}

.article-link a {
  margin-top: 0.5em;
}
