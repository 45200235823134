/* theme- level css*/
@import "./colors.css";

body {
  /* background-color: #f1f6fa; */
  background-color: var(---background-color);
}

html {
  /* height: 100vh; */
}

.ui.loading.segment:before {
  background: none;
}

.ui.pagination.menu {
  background: transparent;
}

.ui.grid {
  margin: 0;
}

/* This will keep the overlap from happening on dropdowns */
.pushable > .pusher {
  min-height: 100vh;
}

/* -- THEME -- */
button.ui.button.primary {
  background-color: var(--lg-blue-dark);
  border: 1px #dce5fd solid;
  color: #eeeeee;
  font-size: 12px;
  font-weight: 500;
  font-family: "Avenir Next Medium";
  border-radius: 3px;
}

button.button.ui.primary:hover {
  background-color: var(--lg-blue-light) !important;
  font-size: 12px;
  font-weight: 500;
  font-family: "Avenir Next Medium";
}

.ui.form .continue-btn.field {
  margin-top: 1rem;
}

.continue-btn.field .ui.button {
  height: 48px;
  border-radius: 2px;
  background-color: var(--lg-blue-medium);
  color: #e2e2e2;
  border: 1px solid var(--lg-blue-medium);
}

.continue-btn.field .disabled.ui.button {
  background-color: var(--lg-blue-medium);
  color: var(--background-color);
}

.continue-btn.field .ui.button:hover {
  background-color: var(--lg-blue-light);
  color: var(--clickable-white-link-highlight);
  border: 1px solid var(--background-dark);
}

.continue-btn.ui.button:active {
  background-color: var(--lg-blue-dark);
}

i.icon.blue {
  color: #275ce0 !important;
}

.ui.blue.menu .active.item,
.ui.menu .blue.active.item {
  border-color: #275ce0;
  color: #275ce0;
}

i.inverted.bordered.blue.icon,
i.inverted.circular.blue.icon,
i.inverted.blue.icon,
.ui.blue.button,
.ui.blue.buttons .button,
.ui.blue.label,
.ui.inverted.blue.segment,
.ui.primary.button,
.ui.primary.buttons .button {
  background-color: #275ce0;
}

.ui.blue.button,
.ui.blue.buttons .button,
.ui.primary.button,
.ui.primary.buttons .button {
  transition: background-color 250ms linear;
}
.ui.blue.button:hover,
.ui.blue.buttons .button:hover,
.ui.primary.button:hover,
.ui.primary.buttons:hover {
  background: rgb(28, 44, 218);
  transition: background-color 250ms linear;
}

.ui.basic.blue.button {
  background-color: #fff;
  color: #275ce0;
}

.ui.blue.header {
  color: #275ce0;
}

.ui.active.visible.floating.top.right.pointing.dropdown.icon:focus-within
  i.icon.big.setting.big {
  color: #959595;
}

input[type="radio"] {
  width: 17px;
  height: 17px;
}

.ui.button {
  border-radius: 0.18em;
  font-size: 0.9rem;
  font-weight: normal;
  padding: 1.1em 1.7em;
}

i.large.icon,
i.large.icons {
  line-height: 0.6;
}

.ui.sidebar {
  overflow-y: visible !important;
}

.ui.steps {
  border: none;
}
.ui.steps .step,
.ui.steps .step:first-child,
.ui.steps .step:last-child {
  margin: 0.75em;
  padding: 0;
  background-color: transparent;
}
.ui.step,
.ui.steps .step {
  font-size: 0.5rem;
  border: none;
}
.ui.ordered.steps .step:before {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background: rgba(34, 36, 38, 0.15);
  margin: 0;
}
.ui.ordered.steps .step:before,
.ui.steps .step .icon {
  color: rgba(34, 36, 38, 0.15);
}
.ui.ordered.steps .step:after {
  content: normal;
}
.ui.steps .step.active {
  background: transparent;
}
.ui.steps .step.active:before {
  background: #ffffff;
  border: 1px solid #275ce0;
}
.ui.ordered.steps .step.active:before,
.ui.steps .active.step .icon {
  color: #275ce0;
}

.ui.modal {
  margin: 0 !important;
}

.modals.dimmer .ui.modal.call,
.ui.modal.call {
  width: 100%;
  height: 100%;
  margin: 0 !important;
}
.ui.modal.call > .content {
  overflow-y: scroll;
  height: 100%;
}

.ui.tiny.modal {
  margin-top: 0 !important;
}

.ui.modal > .close {
  top: 1.0535rem;
  right: 1rem;
  color: var(--text-hollowbutton);
}

.bottom_menu_fixed {
  border-bottom: none;
  border-left: none;
  border-right: none;
  bottom: 0;
  left: 0;
  top: auto;
  right: auto;
  position: fixed;
}

.ui.pagination.menu .active.item {
  color: rgb(39, 92, 224);
}

img.ui.image .lg-logo,
.ui.header > .image:not(.icon),
.ui.header > img {
  display: inline-block;
  margin-top: 0.14285714em;
  width: 150px;
  /* height: 35px; */
  vertical-align: middle;
  margin: 12px 0;
  max-width: none;
}

.ui.ui.top.thin.visible.sidebar.menu {
  height: 65px !important;
}

.meta {
  color: #272727a8 !important;
}

.content .description + .header {
  color: rgb(102, 102, 102) !important;
}

.content {
  color: rgb(108, 109, 109) !important;
}

.description,
.description span {
  color: #000000ad;
}

.description i.huge.icon {
  color: rgb(102, 102, 102) !important;
}

.sub.header span,
.sub.header {
  color: rgb(68, 67, 67);
}

.ui.form input::placeholder,
.ui.dropdown:not(.button) > .default.text {
  color: rgb(118, 118, 118);
}

.skip {
  height: 35px;
  border: #daffff 3px double;
  background: rgb(39, 92, 224);
  color: white;
  display: flex;
  width: 160px;
  top: -40px;
  justify-content: center;
  align-items: center;
  text-decoration: underline solid white;
  transition: top 1s ease-out;
}
.skip:hover,
.skip:focus {
  border: yellow 3px double;
  color: yellow;
  text-decoration: underline solid yellow;
  top: 5px;
  left: 5px;
  z-index: 103 !important;
  position: fixed;
  display: flex;
  transition: top 0.1s ease-in;
}

.skip:active {
  border: #daffff 3px double;
  text-decoration: underline solid #daffff;
  color: #daffff;
  display: flex;
}

div.app-container > div:first-child {
  display: flex;
}

div#content div div.ui.basic.segment {
  padding-top: 0;
}

.content .description i.huge.icon,
i.huge.icons {
  line-height: 1;
  font-size: 3em;
}

/* CARD */

.ui.card.guide {
  box-shadow: 0 0 0 1px #f2f2f2, 0 0 24px 0 rgba(39, 92, 224, 0.2);
}
.ui.card.guide > .content .star.icon,
.ui.cards > .card.guide > .content .star.icon {
  /* opacity: 1; this was hiding the featured star icon on the guide summary page*/
}
.ui.cards > .ui.card.guide > .content > .header {
  margin: 0.75em 0 0.25em 0;
}
.ui.cards > .ui.card.guide > .content > .meta {
  font-size: 0.8em;
}
.ui.cards > .ui.card.guide > .content > .description {
  color: rgba(0, 0, 0, 0.5);
}
.ui.cards > .ui.card.guide > .content > .description.topics {
  text-align: left;
}
.ui.cards > .ui.card.guide > .content > .description.topics > .label.topic {
  background-color: transparent;
  border: 1px solid #f2f2f2;
  padding: 0.5em 0.75em !important;
  font-weight: normal;
  margin-top: 0.125em;
  margin-bottom: 0.125em;
}
.ui.cards > .ui.card.guide > .content > .ui.buttons > .ui.button {
  border-radius: 0.28571429rem;
  font-weight: bold;
}

.ui.cards a.card:hover,
.ui.link.card:hover,
.ui.link.cards .card:hover,
a.ui.card:hover {
  transform: translateY(0px);
}

.ui.cards .ui.card {
  transition: box-shadow 750ms;
  box-shadow: 0 0 0 1px #d4d4d5, 0 1px 0 0 #d4d4d5, 0 1px 3px 0 #bcbdbd;
}

.ui.cards .ui.card:hover {
  box-shadow: 0 0 0 1px #d4d4d5, 0 3px 0 0 rgb(30, 88, 235), 0 1px 3px 0 #bcbdbd;
}

/*Checks and Radios*/

.ui.checkbox input ~ .box:before,
.ui.checkbox input ~ label:before {
  background: var(--check-radio-unselected-background);
  border-color: var(--check-radio-unselected-background);
  transition: all 150ms;
}

.ui.checkbox input:focus ~ .box:before,
.ui.checkbox input:focus ~ label:before,
.ui.checkbox input:hover ~ .box:before,
.ui.checkbox input:hover ~ label:before {
  background: var(--check-radio-unselected-background);
  border-color: var(--lg-blue-light);
}

.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before,
.ui.checkbox input:checked:focus ~ .box:before,
.ui.checkbox input:checked:focus ~ label:before {
  background: var(--lg-blue-medium);
  border-color: var(--check-radio-unselected-background);
}

.ui.radio.checkbox input:checked ~ .box:before,
.ui.radio.checkbox input:checked ~ label:before,
.ui.radio.checkbox input:checked:focus ~ .box:before,
.ui.radio.checkbox input:checked:focus ~ label:before {
  background: var(--lg-blue-medium);
  border-color: var(--check-radio-unselected-background);
}

.ui.checkbox input:checked:hover ~ .box:before,
.ui.checkbox input:checked:hover ~ label:before,
.ui.checkbox input:checked:focus:hover ~ .box:before,
.ui.checkbox input:checked:focus:hover ~ label:before {
  border-color: var(--lg-blue-light);
}

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after,
.ui.checkbox input:checked:focus ~ .box:after,
.ui.checkbox input:checked:focus ~ label:after {
  color: var(--clickable-white-link-highlight);
  content: "\f17b";
  font-family: "LineAwesomeIcons";
  font-size: 0.8rem;
}

.ui.radio.checkbox input:checked ~ .box:after,
.ui.radio.checkbox input:checked ~ label:after,
.ui.radio.checkbox input:checked:focus ~ .box:after,
.ui.radio.checkbox input:checked:focus ~ label:after {
  background-color: var(--clickable-white-link-highlight);
}

/* Pagination */
.ui.pagination.menu {
  margin: 1em;
  display: flex;
  justify-content: center;
  align-content: center;
  border: 0;
  box-shadow: none;
}

.ui.pagination.menu .item {
  background-color: #ffff;
  display: flex;
  justify-content: center;
  align-content: center;
}

.TM {
  font-size: 0.7rem;
  padding: 0.1rem;
}

@media screen and (max-width: 440px) {
  .app-workspace {
    min-width: 300px;
    width: unset;
  }

  .app-workspace .routes {
    display: flex;
    padding: 0.5rem 1rem;
  }
}
