.backboard-circles {
  --circle-speed-1: 6s;
  --circle-speed-2: 12s;
}

.backboard-circles {
  display: flex;
  position: fixed;
  overflow: visible;
  height: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #f1f6fa;
}

.backboard-circles .backboard-circle-1,
.backboard-circles .backboard-circle-2 {
  position: absolute;
}

.backboard-circles .backboard-circle-1 {
  transition: width var(--circle-speed-1), height var(--circle-speed-1),
    top var(--circle-speed-1), right var(--circle-speed-1),
    left var(--circle-speed-1), background-color var(--circle-speed-1);
}
.backboard-circles .backboard-circle-2 {
  transition: width var(--circle-speed-2), height var(--circle-speed-2),
    top var(--circle-speed-2), right var(--circle-speed-2),
    left var(--circle-speed-2), background-color var(--circle-speed-2);
}

.backboard-circles .backboard-circle-1 {
  bottom: -25%;
  right: 30%;
  width: 900px;
  height: 900px;
  border-radius: 100%;
  background-color: #ecf3f7;
}

.backboard-circles .backboard-circle-2 {
  top: -55%;
  right: -25%;
  width: 800px;
  height: 800px;
  border-radius: 100%;
  background-color: #f9fbfd;
}

.backboard-circles .backboard-circle-1.loaded {
  bottom: -45%;
  right: 20%;
}

.backboard-circles .backboard-circle-2.loaded {
  top: -45%;
  right: -35%;
}

@media (max-width: 980px) {
  .backboard-circles .backboard-circle-2 {
    width: 600px;
    height: 600px;
    top: 0%;
  }

  .backboard-circles .backboard-circle-1 {
    width: 700px;
    height: 700px;
  }
}
