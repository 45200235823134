.profile-settings .save {
  display: flex;
  justify-content: center;
}
.profile-settings .save > button.ui.primary.button {
  display: flex;
  justify-content: center;
  background-color: #ffff;
  margin: 2em 0em;
  width: 162px;
  height: 40px;
  background-image: linear-gradient(279deg, #3f74fc, #03a6b9) !important;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.profile-settings .avatar-selector {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 2rem;
  flex-grow: 1;
  width: 100%;
}

.profile-settings .avatar-selector .avatar-dropzone-cta-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.profile-settings .avatar-selector .avatar-dropzone-cta {
  margin-top: 1rem;
}

.profile-settings .avatar-selector .avatar-dropzone {
  display: flex;
  border: 2px dotted #eee;
}

.profile-settings .avatar-selector .avatar-dropzone-cta {
  display: flex;
  margin-top: 1rem;
}
