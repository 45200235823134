.app-nav .app-nav-user-avatar-container {
  display: flex;
  min-height: 70px;
  background: #2365ab;
}

.user-avatar-loader {
  display: flex;
  align-items: center;
  padding-left: 1rem;
}

.user-avatar {
  display: flex;
  cursor: pointer;

  align-content: center;
  width: 100%;
  flex-direction: column;
}

.user-avatar .user-avatar-menu {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: space-between;
  transition: height 350ms ease-in-out;
}

.user-avatar.open {
}

.user-avatar .user-avatar-controlpanel {
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  background-color: rgba(221, 227, 233, 0);
  transition: background-color 250ms ease-in-out;
  padding: 1rem;
}

.user-avatar .user-avatar-controlpanel:hover {
  /* background-color: rgba(221, 227, 233, 0.2); */
  background-color: rgba(17, 54, 91, 0.2);
}

.user-avatar .user-avatar-glyph {
  padding-right: 0.5rem;
}

.user-avatar .user-avatar-name {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-avatar .user-avatar-settings-stateindicator {
  padding: 5px;
  border-radius: 1.25rem;
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;
  transition: transform 500ms linear, background-color 250ms linear;
}

.user-avatar
  .user-avatar-controlpanel:hover
  .user-avatar-settings-stateindicator {
  /* background: #2365ab; */
  background-color: rgba(221, 227, 233, 0.2);
}

.user-avatar.open .user-avatar-settings-stateindicator {
  transform: rotate(360deg);
}

.user-avatar .user-avatar-settings-stateindicator > .icon {
  font-size: 25px;
  height: 25px;
  width: 25px;
  line-height: 25px;
  margin: 0;
  padding: 0;
}

/*Submenu stuff*/

.user-avatar-menu {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-content: flex-start;
  font-family: 'Avenir Next';
}

.user-avatar-menu .user-avatar-menu-item {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.2em;
  border-radius: 5px;
  margin-left: 7px;
  margin-right: 7px;
  padding: 7px;
}

.user-avatar .user-avatar-menu.open .user-avatar-menu-item {
}

.user-avatar-menu .user-avatar-menu-item:last-child {
  margin-bottom: 7px;
}

.user-avatar-menu .user-avatar-menu-item i {
  margin-left: 17px;
}

.user-avatar-menu .user-avatar-menu-item span {
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 0.25rem;
  font-family: 'Avenir Next Bold';
  font-size: 14px;
  font-weight: 600;
}

.user-avatar-menu .user-avatar-menu-item:before {
  background: none;
}

.user-avatar-menu .user-avatar-menu-item:hover {
  background-color: rgba(17, 54, 91, 0.2);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-90px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateX(0px);
  }
  to {
    opacity: 0;
    transform: translateX(-90px);
  }
}
