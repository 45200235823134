@font-face {
  font-family: "Avenir Next";
  src: url("avenir-next-regular.eot?") format("embedded-opentype"),
    url("avenir-next-regular.woff") format("woff"),
    url("avenir-next-regular.ttf") format("truetype"),
    url("avenir-next-regular.svg#avenirnext") format("svg");
}

@font-face {
  font-family: "Avenir Next Medium";
  src: url("avenir-next-medium.eot?") format("embedded-opentype"),
    url("avenir-next-medium.woff") format("woff"),
    url("avenir-next-medium.ttf") format("truetype"),
    url("avenir-next-medium.svg#avenirnextmedium") format("svg");
}

@font-face {
  font-family: "Avenir Next Bold";
  src: url("avenir-next-demi.eot?") format("embedded-opentype"),
    url("avenir-next-demi.woff") format("woff"),
    url("avenir-next-demi.ttf") format("truetype"),
    url("avenir-next-demi.svg#avenirnextbold") format("svg");
}

.avenir {
  font-family: "Avenir Next Medium";
}

.avenir.light {
  font-family: "Avenir Next";
}

.avenir.bold {
  font-family: "Avenir Next Bold";
}
