/* guide Dashboard Specific CSS */
.dashboard-layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 2rem;
}

.dashboard-layout .above-fold {
  display: flex;

  flex-direction: row;
  margin: 0 0 0.5rem 0;
}

.dashboard-layout .upcoming-calls {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/*client - top part, the CTA witht he guide in it*/

/*CTA*/
.dashboard-layout .client-dashboard-cta {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.dashboard-layout .client-dashboard-cta .client-dashboard-cta-message {
  display: flex;
  flex-grow: 1;
  height: 7rem;
  border-radius: 5px;
  background: linear-gradient(
    270deg,
    rgba(67, 167, 119, 1),
    rgba(38, 109, 179, 1),
    rgba(67, 167, 119, 1),
    rgba(38, 109, 179, 1),
    rgba(67, 167, 119, 1)
  );
  background-size: 800% 800%;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: GradientMovement 50s ease infinite;
}

@keyframes GradientMovement {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.dashboard-layout .client-dashboard-cta .client-dashboard-cta-message-title {
  display: flex;
  color: #ffffff;
  font-size: 1.6rem;
  padding: 0.4rem;
  font-family: "Avenir Next Bold";
}

.dashboard-layout .client-dashboard-cta .client-dashboard-cta-message-subtitle {
  display: flex;
  color: #ffffff;
  font-size: 1.25rem;
  font-family: "Avenir Next";
}

.dashboard-layout
  .client-dashboard-cta
  .client-dashboard-cta-message-subtitle
  > sup {
  top: 0.25rem;
}

/*GUIDE*/
.dashboard-layout .client-dashboard-cta .client-dashboard-cta-guide {
  display: flex;
  flex-grow: 1;
  height: 7rem;
  border-radius: 5px;
  margin-top: 1rem;
  align-items: center;
}

.dashboard-layout .client-dashboard-cta .client-dashboard-cta-guide-name {
  font-size: 32px;
  font-family: "Avenir Next";
  padding: 1rem;
}

.dashboard-layout .client-dashboard-cta .client-dashboard-cta-guide-nomatch {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-grow: 1;
  font-size: 22px;
  font-family: "Avenir Next";
  padding: 1rem;
}

.dashboard-layout
  .client-dashboard-cta
  .client-dashboard-cta-guide-nomatch-number {
  font-size: 24px;
  font-family: "Avenir Next";
  padding-left: 0.5rem;
}

.dashboard-layout
  .client-dashboard-cta
  .client-dashboard-cta-guide
  .summary-badges {
  font-family: "Avenir Next";
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  padding: 2rem;
}

.dashboard-layout
  .client-dashboard-cta
  .client-dashboard-cta-guide
  .summary-badge {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.dashboard-layout
  .client-dashboard-cta
  .client-dashboard-cta-guide
  .summary-badge
  .summary-badge-info-label {
  font-family: "Avenir Next Bold";
}

.dashboard-layout
  .client-dashboard-cta
  .client-dashboard-cta-guide
  .summary-badge
  .icon {
  padding-right: 1rem;
}

/*SCHEDULE NOW BUTTON*/
.dashboard-layout
  .client-dashboard-cta
  .client-dashboard-cta-guide
  .client-call-schedule-button {
  border-radius: 25px;
  background-image: linear-gradient(
    282deg,
    rgba(38, 109, 179, 1),
    rgba(67, 167, 119, 1)
  );
  background-size: 250% 250%;
  transition: border 500ms;
  align-items: center;
  display: flex;

  font-family: "Avenir Next Bold";
}

.dashboard-layout
  .client-dashboard-cta
  .client-dashboard-cta-guide
  .client-call-schedule-button
  span {
  color: #ffffff;
}

.dashboard-layout .divide-fold {
  display: flex;
  height: 1px;
  border-bottom: 1px solid #dfdfdf;
}

.dashboard-layout .below-fold {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  flex-grow: 1;
}

.client-dashboard-guide-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.client-dashboard-guide-details .guide-details-video {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.client-dashboard-guide-details .guide-details-video-frame {
  display: flex;
  height: 270px;
  flex-direction: row;
}

.guide-details-video-frame .img-billboard {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.guide-details-video-frame .img-billboard .billboard-cta {
  display: flex;
  flex-grow: 1;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.client-dashboard-guide-details .guide-details-about {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.client-dashboard-guide-details .guide-subjects {
  margin-top: 2rem;
  display: flex;
  align-items: flex-start;
  font-family: "Avenir Next";
  justify-items: flex-start;
  margin: 1.5rem 0;
}

.client-dashboard-guide-details .guide-subjects .subjects-title {
  font-family: "Avenir Next Bold";
}

.client-dashboard-guide-details .guide-subjects .expert-subjects {
  display: flex;
}

.client-dashboard-guide-details
  .guide-subjects
  .expert-subjects
  .expert-subject-li {
  color: #3b404d;
  border: 1px solid #f3f3f3;
  padding: 1rem;
  border-radius: 20px;
  margin: 0.5rem;
}

.client-dashboard-guide-details .guide-subjects .expert-subject-li:first-child {
  margin-left: 0;
}

.client-dashboard-guide-details .guide-details-about-bio {
  font-size: 1.25rem;
  line-height: 1.89;
  font-family: "Avenir Next";
}

.guide-details-header {
  display: flex;
  font-size: 1.75rem;
  font-family: "Avenir Next Bold";
  padding: 1rem 0;
}

.dashboard-layout .completed-calls {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
}

.dashboard-layout .profile-steps-progress {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  max-width: auto;
  margin-left: 0rem;
  margin-top: 2rem;
}

@media (max-width: 980px) {
  .dashboard-layout {
    padding: 1rem;
  }
  .dashboard-layout .client-dashboard-cta .client-dashboard-cta-guide-name {
    font-size: 28px;
    padding: 0.5rem;
    padding-right: 0rem;
  }

  .dashboard-layout
    .client-dashboard-cta
    .client-dashboard-cta-guide
    .summary-badges {
    padding: 1rem;
    padding-left: 0rem;
  }
  .dashboard-layout
    .client-dashboard-cta
    .client-dashboard-cta-guide
    .client-call-schedule-button {
    padding: 0.25rem;
  }
  .dashboard-layout
    .client-dashboard-cta
    .client-dashboard-cta-guide
    .client-call-schedule-button
    span {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }
}

@media (max-width: 860px) {
  .dashboard-layout .client-dashboard-cta .client-dashboard-cta-guide-name {
    font-size: 17px;
    padding: 0.15rem;
  }
  .dashboard-layout
    .client-dashboard-cta
    .client-dashboard-cta-guide
    .client-call-schedule-button
    span {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

@media (max-width: 768px) {
  .dashboard-layout .client-dashboard-cta .client-dashboard-cta-guide {
    flex-direction: column;
    height: auto;
  }

  .dashboard-layout .client-dashboard-cta .client-dashboard-cta-guide-name {
    font-size: 24px;
    padding: 0.5rem;
  }

  .dashboard-layout
    .client-dashboard-cta
    .client-dashboard-cta-guide
    .client-call-schedule-button
    span {
    font-size: 1.75rem;
    line-height: auto;
    padding: 1rem;
  }

  .dashboard-layout
    .client-dashboard-cta
    .client-dashboard-cta-guide
    .client-call-schedule-button {
    margin-bottom: 1rem;
  }
}
