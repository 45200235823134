.guides-pager-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.guides-pager.ui.pagination {
  background: transparent;
}
