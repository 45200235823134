.calls-tab {
  display: flex;
  flex-direction: column;
  margin-top: 2.5em;
  flex-grow: 1;
}

.calls-tab .calls-tab-list {
  display: flex;
  flex-direction: column;
}

.calls-tab .calls-tab-list .item,
.calls-tab .calls-tab-list .calls-tab-list-call-entry {
  padding: 1rem 0.5rem;
  display: flex !important;
  flex-direction: row;
}

.call-tabs .header.list-header {
  display: flex;
  flex-direction: column;
}

.calls-tab .calls-tab-list .ui.floating.top.right.pointing.dropdown.icon {
  display: flex;
  position: relative !important;
  top: 0 !important;
  right: 0 !important;
  justify-content: flex-end;
}

.scheduled-call-entry-dropdown.icon .icon {
  color: #bbb;
  margin-top: 0;
}

.call-requests-list-view .content.call-request-list-content {
  display: flex;
  align-items: center;
}

.call-requests-list-view .content.call-request-list-content button {
  border-radius: 3px;

  background-color: #ffffff !important;
  color: #5d657b;
  border: 1px #dce5fd solid !important;
}

.app-workspace-routes-container .routes .calls-tab .menu a.item,
.app-workspace-routes-container .routes .calls-tab .menu .item .sub.header {
  font-family: "Avenir Next Medium";
}

.calls-tab .menu a.item.active,
.calls-tab .menu a.active.item .sub.header {
  color: #2a62f0;
  border-bottom-color: #2a62f0;
  transition: color, weight 250ms linear;
}

@media screen and (max-width: 600px) {
  .calls-tab {
    width: 300px;
  }
  .callentry-list-item .calls-tab .list-item-container {
    flex-direction: column;
  }

  .ui.secondary.pointing.menu .item {
    padding: 0.8rem 0.2rem;
  }
  .ui.secondary.pointing.menu.stackable .item.active {
    background-color: rgba(0, 0, 0, 0.02);
  }
}

/* .call-tab .stackable {
  min-width: 250px;
} */
