.password-registration-form.ui.form {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

@media (max-width: 980px) {
    .password-registration-form.ui.form {
        max-width: 90%;
    }
}