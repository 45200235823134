.social-button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.social-button-container > .facebook,
.social-button-container > .google {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 48%;
}

.social-button-container > .facebook > .ui.button,
.social-button-container > .google > .ui.button {
  height: 40px;
  border-radius: 2px;
  border: solid 1px #c5cfd6;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  padding: 0;
  box-shadow: none;
  flex-grow: 1;
}

.social-button-container .google-logo,
.social-button-container .facebook-logo {
  width: 20px;
  height: 20px;
  margin-right: 1rem;
}

@media (max-width:980px) {
  .social-button-container {
    width: 90%;
  }
}