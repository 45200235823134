@import "../../colors.css";

.option-select {
    padding: 0;
    margin: 1em 0em;
}

.option-select .options-list {
    margin: 0em auto;
    text-align: left;
}

.option-select .options-list .option {
    padding: 0.65em 0.3em;
}