.send-call-request {
  display: flex;
  /* flex-grow: 1; */
  text-align: center;
  align-items: center;
  justify-content: center;
}

.send-call-request .loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*taken from user-dialog.css*/

.modals.ui.page.dimmer.transition.visible.active {
  display: flex !important;
}

.modal.send-call-request.ui.modal.transition.visible.active {
  width: 300px;
  height: 550px;
  border-radius: 4px;
}
.modal .send-call-request,
.modal .send-call-request .content,
.modal .send-call-request .description,
.modal .send-call-request .description span,
.modal .send-call-request .header,
.modal .send-call-request .subtitle {
  color: #ffffff;
  font-family: "Avenir Next";
}

.modal .send-call-request {
  /* background: linear-gradient(
    180deg,
    rgba(67, 167, 119, 1) 0%,
    rgba(38, 109, 179, 1) 50%
  ); */
}

.modal .send-call-request.content {
  padding: 40px 30px;
  overflow-y: hidden;
  flex-direction: column;
}

.modal .send-call-request.content h1.ui.header {
  font-size: 22px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.27;
  letter-spacing: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal .send-call-request.content .sub.header {
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal .send-call-request.content .sub.header h5 {
  font-family: "Avenir Next";
  font-size: 12px;
  font-weight: 500;
}

.modal .send-call-request.content .sub.header h5 i {
  margin-right: 0;
}

.modal .send-call-request.content .description .sub.header .sub.header {
  width: 240px;
  height: 48px;
  opacity: 0.8;
  font-family: "Avenir Next";
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 35px;
}

.modal .send-call-request.content .description .sub.header + div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal .send-call-request.content .ui.grid {
  margin-top: -1rem;
  margin-bottom: -1rem;
  width: 240px;
  display: flex;
}

.modal .send-call-request.content .ui.grid .row {
  display: flex;
  justify-content: space-between;
  margin-top: 0;
}

.modal .send-call-request.content .ui.two.column.grid .column {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  width: 115px;
}

button.send-call-request.button.primary {
  background-color: #638dee !important;
  border-radius: 3px;
  font-size: 14px;
  margin: 30px 0px 0px 0px;
}
