.nocredits {
  padding: 2rem;
}

.nocredits .button.ui.primary {
  color: #eeeeee;
}

@media (max-width: 768px) {
  .nocredits .button.ui.primary {
    color: #333333;
  }

  .nocredits .button.ui.primary:hover {
    color: #eeeeee;
  }
}
