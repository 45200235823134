.question-container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  min-width: 50%;
  justify-content: center;
}

.question-container .step-container.ui.ordered.unstackable.steps {
  margin-bottom: 0.75rem;
}

.question-container .step-container.ui.ordered.unstackable.steps .step {
  font-size: 0.35rem;
}

.question-container .step-container.ui.ordered.unstackable.steps .step:before {
  width: 1.75rem;
  height: 1.75rem;
}
