.app-container {
  display: flex;
  height: 100vh;
}

.app-bar {
  flex: none;
  height: 67px;
}

body,
html {
  display: flex;
  flex: 1;
}

#root {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.app-nav-workspace-container {
  display: flex;
  width: 100vw;
}

.header-and-workspace-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.app-footer-container {
  display: flex;
}
