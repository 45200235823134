/*onboard dialog*/
.onboard-dialog {
    width: 450px;
    text-align: center;
    padding: 0.5rem 2.5rem;
  }
  
  .onboard-dialog .header-and-subheader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .onboard-dialog .modal-action-btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
  }
  
  .onboard-dialog .modal-action-btns .continue-btn.save-btn {
    display: flex;
    width: 150px;
    justify-content: center;
  }
  
  .onboard-dialog  .modal-action-btns .skip-btn{
    color: #666666;
    box-shadow: none;
    width: 150px;
  }
  
  /*intake questions*/
  .onboard-dialog .intake-questions {
    display: flex;
    flex-direction: column;
  }
  
  .onboard-dialog .intake-questions .options-and-btns {
    margin: 0.5em auto;
    display: flex;
    flex-direction: column;
  }
  
  .onboard-dialog
    .intake-questions
    .options-and-btns
    .continue-btn {
      display: flex;
      justify-self: center;
      align-self: center;
      align-items: center;
      justify-content: center;
      width: 150px;
  }
  
  /*select subchannel*/
  .onboard-dialog .subchannel {
    display: flex;
    flex-direction: column;
  }
  
  .onboard-dialog .subchannel .select-subchannel {
  }
  
  .onboard-dialog .subchannel .selected-subchannels-amt {
    display: flex;
    justify-content: center;
    margin: 1em 0em;
  }
  
  