.registration .spouse-invite-form {
  display: flex;
  min-width: 400px;
  justify-content: center;
  text-align: left;
}

.registration .spouse-invite-form .ui.form {
  flex-grow: 1;
}

.registration .spouse-invite-form .ui.form .fields {
  justify-content: space-between;
}

.registration .spouse-invite-form .ui.form .fields .field {
  padding-left: 0;
  padding-right: 0;
  max-width: 48%;
}

.registration .spouse-invite-form .ui.form .fields .field:last-child {
  margin-right: 0.5rem;
}
.registration .spouse-invite-form .ui.form .fields .field:first-child {
  margin-left: 0.5rem;
}
