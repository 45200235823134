:root {
  --lg-blue-darker: #2365ab;
  --lg-blue-dark: #256cb5;
  --lg-blue-medium: #2e71b7;
  --lg-blue-light: #427fbf;

  --green-blue-gradient: 180deg, rgba(67, 167, 119, 1) 0%,
    rgba(38, 109, 179, 1) 50%;

  --clickable-link: #4f6bb3;
  --clickable-white-link: #fffeff;
  --clickable-white-link-highlight: #ffffff;

  --background-color: #fffeff;
  --title-text: #0e0e0e;
  --border-hollowbutton: #e5e5e5;
  --text-hollowbutton: #424242;
  --border-hollowbuttonhighlight: #0e0e0e;

  --background-color-test: #ff3366;

  --check-radio-unselected-background: #e7e8eb;
}
