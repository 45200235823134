.guides-list-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.guides-list {
  display: flex;
  flex-direction: column;
  max-width: 1080px;
}

@media (min-width: 1080px) {
  .guides-list {
    max-width: 1280px;
  }

  .guides-list.small-set {
    max-width: 1080px;
  }
}

.guides-list .ui.cards {
  display: flex;
  justify-content: center;
}
