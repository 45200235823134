.guide-details-container {
  display: flex;
  align-items: center;
}

.guide-details {
  align-content: flex-start;
  align-items: flex-start;
  padding: 0 0.5rem 0 0;
  height: 12em;
  margin: 2rem 0rem;
}

.guide-details ::-webkit-scrollbar {
  width: 4px;
  height: 51px;
  border-radius: 2px;
  background-color: #d8d8d8;
}

.guide-details-bio-title {
  display: flex;
  min-width: 115px;
  font-family: "Avenir Next Medium";
  font-weight: 600;
  color: #3b404d;
  padding-bottom: 0.5rem;
  font-size: 16px;
  line-height: 16px;
}

.guide-details-bio {
  display: flex;
  font-family: "Avenir Next"; /*We need Avenir Book*/
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.89;
  letter-spacing: normal;
  color: #5d657b;
  overflow-y: auto;
  height: 9em;
  overflow-y: auto;
}

.guide-details-menu .ui.pointing.secondary.menu {
  border-bottom: solid 2px rgba(30, 88, 235, 0.17);
  margin-bottom: 20px;
}

.guide-details-menu .ui.pointing.secondary.menu .active.item {
  border-bottom: solid 2.2px #1e58eb;
  padding-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #242424;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Avenir Next Medium";
}

.guide-details-menu,
.guide-details-menu p {
  display: flex;
  flex-direction: column;
  font-family: "Avenir Next";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.89;
  letter-spacing: normal;
  color: #5d657b;
}

.guide-details-menu .ui.feed > .event {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0.21428571rem 0;
  margin: 0;
  background: 0 0;
  border-top: none;
}

.guide-details-menu .ui.feed > .event > .label .icon {
  height: 100%;
  padding: 0;
}

.ui.feed > .event > .label {
  display: block;
  flex: 0 0 auto;
  width: 2.5em;
  height: 100%;
  align-items: flex-start;
  display: flex;
}

.guide-details .segment {
  display: flex;
  border: 0;
  box-shadow: none;
  background: none;
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  padding: 0 0.5rem 0 0;
  height: 10rem;
  overflow-y: auto;
  margin-top: 0;
}

.guide-summary .guide-paragraph {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3b404d;
}

/** is-compressed **/
.guide-details.is-compressed {
}

.guide-details.is-compressed .segment {
}

.guide-details.is-compressed .guide-details-menu .segment {
  height: 100%;
}

.guide-summary.is-compressed .summary-information {
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .guide-details {
    width: 100%;
    height: 100%;
    margin-top: 2em;
  }
  .guide-summary + .guide-details .guide-details-menu .segment {
    height: auto;
  }
  .guide-summary.is-compressed .summary-information {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
}
