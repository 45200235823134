.img-billboard {
  display: flex;
  flex-grow: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #cccccc;
  flex-direction: row;
}

.img-billboard-tint {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  min-width: 400px;
  min-height: 400px;
  background: linear-gradient(
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 2;
  width: 100%;
  height: 100%;
}

@media (max-width: 980px) {
  .registration-container .img-billboard {
    background-position: center;
    background-position-y: 25%;
    height: 250px;
    min-height: auto;
  }
}
