.guide-subjects {
  display: flex;
  flex-direction: column;
}

.guide-subjects .subjects-title {
  display: flex;
  min-width: 115px;
  font-family: "Avenir Next Medium";
  font-weight: 600;
  color: #3b404d;
  padding-bottom: 0.5rem;
  font-size: 16px;
}

.guide-subjects .expert-subjects {
  display: inline;
}

.guide-subjects .expert-subject-li {
  font-family: "Avenir Next";
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: #5d657b;
  display: inline;
}

.guide-subjects .expert-subject-li .expert-subject-li-delimiter {
  padding-right: 0.25rem;
  display: inline-flex;
}

.guide-subjects .expert-subject-li .expert-subject-li-title {
  display: inline-flex;
}

@media only screen and (max-width: 767px) {
  .guide-subjects {
    flex-direction: column;
    margin-top: 2em;
  }

  .guide-subjects .expert-subjects {
    flex-direction: column;
  }

  .guide-subjects .expert-subject-li {
    font-size: 12px;
  }
}
