/* send-message-modal specific css*/

.send-message-modal .header-container{
    flex:1 ;
    flex-direction: row;
    margin: 0.5rem 0rem 1.2rem 0rem;
}

.send-message-modal .ui.avatar.image {
    width: 2.2em;
    height: 2.2em;
    display: inline-block;
}

.send-message-modal .ui.header{
    display: inline-block;
    vertical-align: top;
    padding-left:1rem;
    margin: 0;
}