@import "../../colors.css";

.app-header.portal {
  flex-direction: row;
  display: flex;
  z-index: 999;
  width: 100%;
  font-family: "Avenir Next";
  color: rgba(255, 255, 255, 1);
  padding: 2.5rem 2rem;
  background: transparent;
  border-bottom: none;
}

.app-header.portal .ui.basic.button {
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 16px;
  padding: 0;
}

.app-header.portal .ui.dropdown > .dropdown.icon {
  align-self: center;
}

.app-header.portal .ui.basic.button:hover {
  color: rgba(255, 255, 255, 1) !important;
  background: none !important;
}

.app-header.portal .appheader-logo-signin-container {
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
}

.app-header.portal .appheader-logo {
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
}

.app-header.portal .appheader-logo-login-divider {
  border-left: 1px solid var(--background-color);
  margin: 0 0.45rem;
}

.app-header.portal .appheader-user-component-container {
  display: flex;
  flex-direction: column;
}

.app-header.portal .portal.app-header-avatar {
  display: flex;
}

.app-header.portal .appheader-call-chat-cta-container {
  display: flex;
}

.app-header.portal .appheader-call-cta {
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.app-header.portal .portal-header-phone-digits {
  color: var(--clickable-white-link);
  padding: 0 0.5rem;
  display: inline-block;
  white-space: nowrap;
}

.fancy-underlined-link:before {
  content: "";
  position: relative;
  display: block;
  width: calc(100% - 0.5rem);
  height: 1.5px;
  border: 0px solid transparent;
  top: 1.5rem;
  left: 0.25rem;
  background-color: var(--clickable-white-link-highlight);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
  box-shadow: 0 0 5px 0.5px var(--clickable-white-link);
}

.fancy-underlined-link:hover:before {
  color: var(--clickable-white-link-highlight);
  visibility: visible;
  transform: scaleX(1);
}

.hollow-button.ui.button {
  border: 1px solid var(--clickable-white-link);
  color: var(--clickable-white-link);
  transition: all 0.4s ease-in-out 0s, box-shadow 0.2s ease-in-out 0.2s;
  background-color: transparent;
  box-shadow: 0 0 0 0 var(--clickable-white-link);
  cursor: pointer;
}

.hollow-button.ui.button:hover {
  border: 1px solid var(--clickable-white-link-highlight);
  color: var(--lg-blue-dark);
  background-color: var(--clickable-white-link);
  box-shadow: 0 0 10px 1px var(--clickable-white-link);
}

@media (max-width: 600px) {
  .app-header.portal {
    padding: 0.5rem;
  }

  .app-header .appheader-logo {
    width: 110px;
  }

  .app-header .avatar-container {
    min-width: 66px;
  }

  .app-header.portal .appheader-call-cta {
    padding: 0 0.5rem;
  }
}

@media (max-width: 440px) {
  .app-header.portal .appheader-logo-signin-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .app-header.portal .appheader-logo {
    margin-bottom: 0.25rem;
  }

  .app-header.portal .avatar-container {
    min-width: 60px;
  }

  .app-header .hollow-button.ui.button {
    line-height: 1.4rem;
    padding: 0.5rem;
  }
}
