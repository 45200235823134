@import "../../colors.css";

.app-header.onboard {
  flex-direction: row;
  display: flex;
  z-index: 999;
  width: 100%;
  font-family: "Avenir Next";
  color: rgba(255, 255, 255, 1);
  padding: 2.5rem 2rem;
  background: transparent;
  background: linear-gradient(
    173deg,
    rgba(0, 0, 0, 0.15) 0%,
    rgba(0, 0, 0, 0.1) 10%,
    rgba(38, 57, 79, 0) 25%
  );
  border-bottom: none;
}

.app-header.onboard .ui.basic.button {
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 16px;
  padding: 0;
}

.app-header.onboard .ui.dropdown > .dropdown.icon {
  align-self: center;
}

.app-header.onboard .ui.basic.button:hover {
  color: rgba(255, 255, 255, 1) !important;
  background: none !important;
}

.app-header.onboard .appheader-logo-signin-container {
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
}

.app-header.onboard .appheader-logo-link {
  display: flex;
}

.app-header.onboard .appheader-logo {
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
}

.app-header.onboard .appheader-logo-login-divider {
  border-left: 1px solid var(--background-color);
  margin: 0 0.45rem;
}

.app-header.onboard .appheader-company {
  line-height: 30px;
  padding: 0 0.5rem;
}

.app-header.onboard .appheader-user-component-container {
  display: flex;
  flex-direction: column;
}

.app-header.onboard .onboard.app-header-avatar {
  display: flex;
}

.app-header.onboard .appheader-call-chat-cta-container {
  display: flex;
}

.app-header.onboard .app-header-call-chat {
  width: max-content;
}

.app-header.onboard .appheader-call-cta {
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.app-header.onboard .onboard-header-phone-digits {
  color: var(--clickable-white-link);
  padding: 0 0.5rem;
  display: inline-block;
}

.fancy-underlined-link:before {
  content: "";
  position: relative;
  display: block;
  width: calc(100% - 0.5rem);
  height: 1.5px;
  border: 0px solid transparent;
  top: 1.5rem;
  left: 0.25rem;
  background-color: var(--clickable-white-link-highlight);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
  box-shadow: 0 0 5px 0.5px var(--clickable-white-link);
}

.fancy-underlined-link:hover:before {
  color: var(--clickable-white-link-highlight);
  visibility: visible;
  transform: scaleX(1);
}

.hollow-button.inverted.ui.button {
  border: 1px solid var(--border-hollowbutton);
  color: var(--text-hollowbutton);
  transition: all 0.4s ease-in-out 0s, box-shadow 0.2s ease-in-out 0.2s;
  background-color: transparent;
}

.hollow-button.inverted.ui.button:hover {
  border: 1px solid var(--border-hollowbuttonhighlight);
  color: var(--border-hollowbuttonhighlight);
  background-color: var(--clickable-white-link);
}

.app-header.onboard .onboard-header-label {
  color: var(--text-hollowbutton);
}

@media (max-width: 980px) {
  .app-header.onboard .appheader-chat-cta .button.hollow-button.inverted{
    color: var(--clickable-white-link);
    box-shadow: 0 0 0 0 var(--clickable-white-link);
  }

  .app-header.onboard .appheader-chat-cta .button.hollow-button.inverted:hover,
  .app-header.onboard .appheader-chat-cta .button.hollow-button.inverted:active 
  {
    background-color: rgba(255,255,255,0.9);
    color: var(--text-hollowbutton);
    border: 1px solid var(--border-hollowbutton);
  }
}

@media (max-width: 520px) {
  .app-header.onboard .appheader-call-cta {
    display: none;
  }
}