@import '../../colors.css';

/*TODO: merge this css with onboard, they are very similar*/

.registration-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.screen-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.registration-container {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}

.registration-header {
  display: flex;
  height: 0;
  overflow: visible;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding-top: 70px;
}

.step-container {
  display: flex;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.registration .concierge-info .button {
  margin: 0 0.5em;
}

.registration .registration-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  max-width: 420px;
  /* min-width: 380px; */
  min-width: 350px;
}

.registration .registration-step > p {
  max-width: 380px;
  margin: 0.5rem 0 1.5rem 0;
  line-height: 24px;
  font-size: 15px;
}

.registration .registration-step .divider.horizontal {
  /* width: 415px; */
  height: 0;
  width: 90%;
  max-width: 415px;
}

.registration .registration-step.self-invite .company-logo  {
  max-width: 300px;
  margin: 0 1em 0 2em;
}

.registration .registration-step.contact-concierge .message {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.registration .registration-step h1 {
  text-align: center;
}

.onboard-header-txt {
  color: #0e0e0e;
  font-family: 'Lato Medium';
  font-weight: 500;
}

@media (max-width: 980px) {
  .form-container {
    flex-shrink: 1;
  }

  .registration-wrapper {
    overflow: auto;
  }

  .registration-container {
    flex-direction: column;
    overflow: auto;
  }

  .registration-container .img-billboard-tint {
    height: auto;
    min-height: 250px;
    min-width: 100%;
  }

  .app-header.onboard .onboard-header-label {
    color: #fff;
  }
}

