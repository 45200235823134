@import '../../colors.css';

.call-scheduler {
  position: relative;
  display: flex;
  width: 300px;
  flex-direction: column;
  padding: 3rem 2rem;
  height: 100%;
  min-height: 480px;
  justify-content: center;
  background-color: var(--lg-blue-medium);
  color: #ffffff;
  font-family: 'Avenir Next';
  border-radius: 0px 4px 4px 0px;
}

.call-scheduler .about-guide-scheduling {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}

.call-scheduler .about-guide-scheduling .talk-to-guide {
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 700;
  flex-grow: 1;
  align-items: center;
}

.call-scheduler .about-guide-scheduling .talk-to-guide .talk-to-name {
  font-family: 'Avenir Next Bold';
  line-height: 24px;
}

.call-scheduler .about-guide-scheduling .availability-scheduler-details {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
}

.call-scheduler
  .about-guide-scheduling
  .availability-scheduler-details
  .guide-availability {
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  flex-direction: row;
  margin-bottom: 15px;
}

.call-scheduler
  .about-guide-scheduling
  .availability-scheduler-details
  .guide-availability
  i.guide-availability-icon {
  display: flex;
  justify-content: center;
}

.call-scheduler
  .about-guide-scheduling
  .availability-scheduler-details
  .guide-availability
  span.guide-availability-text {
  display: flex;
  justify-content: center;
  font-family: 'Avenir Next Medium';
}

.call-scheduler
  .about-guide-scheduling
  .availability-scheduler-details
  .user-availability-text {
  display: flex;
  justify-content: center;
  color: rgba(255, 255, 255, 0.8);
  flex-grow: 2;
}

.call-scheduler
  .about-guide-scheduling
  .availability-scheduler-details
  .user-availability-text
  span {
  opacity: 0.8;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.call-scheduler
  .about-guide-scheduling
  .availability-scheduler-details
  span.guide-availability-item.first-availability {
  margin-left: 0.2rem;
}

.call-scheduler
  .about-guide-scheduling
  .availability-scheduler-details
  span.guide-availability-time-delimiter {
  margin-right: 0.2rem;
}

.call-scheduler .guide-suggested-times-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.call-scheduler .guide-suggested-times {
  display: flex;
  padding: 0.2rem 0.5rem;
  color: white !important;
}

.call-scheduler .guide-suggested-times-container .guide-suggested-times-row {
  padding-bottom: 1.5rem;
  padding-top: 0rem;
}
.call-scheduler
  .guide-suggested-times-container
  .guide-suggested-times-row:last-child {
  padding-bottom: 0.2rem;
  padding-top: 0rem;
}

/* What's a listbox? */
.call-scheduler .guide-suggested-dates-row listbox,
.call-scheduler .guide-suggested-times-row listbox {
  opacity: 0.5;
  border-radius: 2px;
  border: solid 1px #9daab2;
}

.call-scheduler .guide-suggested-times-container .ui.dropdown {
  background-color: var(--lg-blue-medium);
  border-radius: 2px;
  border: solid 1px #9daab2;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  padding: 0;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.call-scheduler .guide-suggested-times-container .visible.menu.transition {
  background: var(--lg-blue-dark);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 1px;
}

.call-scheduler .guide-suggested-times-container .ui.dropdown > .text {
  display: flex;
  font-family: 'Avenir Next Medium';
}

.call-scheduler
  .guide-suggested-times-container
  .ui.button.fluid.scrolling.dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
}

.call-scheduler .guide-suggested-times-container span {
  color: white !important;
}

.call-scheduler .ui.grid > .row > .column {
  display: flex;
  flex: 50%;
  padding: 0rem 0.5rem !important;
}

.call-scheduler .scheduler-close-video {
  display: flex;
  /* flex: 1 1; */
  /* padding-bottom: 1rem; */
  padding: 0.5rem;
  padding-top: 1rem;
}

.call-scheduler .scheduler-close-video .scheduler-close-video-button {
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  margin-right: 0;
  height: 45px;
  font-family: 'Avenir Next';
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;

  letter-spacing: -0.2px;
  text-align: center;
  padding: 0.25rem;
  color: var(--lg-blue-medium);
  background: rgba(255, 255, 255, 0.9);
}

.call-scheduler .scheduler-close-video .scheduler-close-video-button:hover {
  background: rgba(255, 255, 255, 1);
  transition: background-color 250ms linear;
}

.call-scheduler .ui.inverted.grey.button,
.ui.inverted.grey.buttons .button {
  box-shadow: none !important;
  border: solid 1px #9daab2;
}

.call-scheduler .scheduler-cta {
  display: flex;
  justify-content: center;
}

.call-scheduler .scheduler-cta {
  padding: 0.5rem;
}

.call-scheduler .scheduler-cta .call-scheduler-button.ui.button.primary {
  display: flex;
  justify-content: center;
  background-color: var(--lg-blue-light);
  border-radius: 3px;
  height: 45px;
  margin-right: 0;
  font-family: 'Avenir Next';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.2px;
  text-align: center;
  color: #ffffff;
  border: 1px solid transparent;
  transition: border 50ms, color 500ms, background 1s;
}

.call-scheduler .scheduler-cta .call-scheduler-button.ui.button.primary:hover {
  background: linear-gradient(#fff, #fff);
  border: 1px #03a6b9 solid;
  color: #346eff;
}

.call-scheduler .guide-suggested-times {
  padding: 0;
}

@media screen and (max-width: 767px) {
  .call-scheduler {
    border-radius: 0;
    flex-grow: 1;
  }
}
