@import "../../colors.css";

.portal .portal-header {
  --circle-speed-1: 20s;
  --circle-speed-2: 30s;
}

.portal .portal-header {
  background: var(--lg-blue-dark);
  text-align: center;
  display: flex;
  flex-direction: column;
  color: #fff;
  font-family: "Avenir Next";
  flex-grow: 1;
  width: 100%;
  padding: 1rem;
  margin-top: -70px;
  padding-top: 86px;
  overflow: hidden;
}

.portal .portal-header .portal-header-circles {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  overflow: visible;
}

.portal .portal-header .portal-header-circle-1,
.portal .portal-header .portal-header-circle-2 {
  position: absolute;
}

.portal .portal-header .portal-header-circle-1 {
  transition: width var(--circle-speed-1), height var(--circle-speed-1),
    top var(--circle-speed-1), right var(--circle-speed-1),
    left var(--circle-speed-1), background-color var(--circle-speed-1);
}
.portal .portal-header .portal-header-circle-2 {
  transition: width var(--circle-speed-2), height var(--circle-speed-2),
    top var(--circle-speed-2), right var(--circle-speed-2),
    left var(--circle-speed-2), background-color var(--circle-speed-2);
}

.portal .portal-header .portal-header-circle-1 {
  background-color: var(--lg-blue-medium);
  top: 100px;
  left: -250px;
  width: 800px;
  height: 800px;
  border-radius: 100%;
}

.portal .portal-header .portal-header-circle-2 {
  background-color: var(--lg-blue-light);
  top: -400px;
  right: -200px;
  width: 500px;
  height: 500px;
  border-radius: 100%;
}

.portal .portal-header.focusing-video .portal-header-circle-1 {
  width: 500px;
  height: 500px;
  top: -400px;
  background-color: var(--lg-blue-light);
}

.portal .portal-header.focusing-video .portal-header-circle-2 {
  width: 800px;
  height: 800px;
  top: 100px;
  background-color: var(--lg-blue-medium);
}

.portal .portal-header .portal-header-content {
  display: flex;
  max-width: 980px;
  flex-direction: row;
  padding-bottom: 2rem;
  justify-self: center;
  align-self: center;
  z-index: 11;
}

.portal .portal-header .portal-header-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  text-align: left;
  padding: 0 1rem 0 0;
}

.portal .portal-header .portal-header-info .portal-header-info-header {
  font-size: 28px;
  padding: 0.75rem 0;
  line-height: 32px;
  font-weight: bold;
}

.portal .portal-header .portal-header-info .portal-header-info-content {
  font-size: 20px;
  padding: 0.5rem 0;
  line-height: 28px;
}

.portal .portal-header .portal-header-info .portal-header-info-callout {
  font-size: 22px;
  font-weight: bold;
  padding: 0.75rem 0;
  line-height: 28px;
}

.portal .portal-header .portal-header-video {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.portal .portal-header .image-billboard {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.portal .portal-header .portal-header-video-splash {
  width: 550px;
  height: 320px;
  background-color: var(--clickable-white-link);
  border-radius: 10px;

  transition: all 0.2s ease-in-out 0s, box-shadow 0.2s ease-in-out 0.1s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px 1px rgba(255, 255, 255, 0);
  border: 1px solid var(--lg-blue-dark);
}

.portal .portal-header .portal-header-video-splash:hover {
  box-shadow: 0 0 10px 1px rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 1);
}

.portal .portal-header .portal-header-video-splash-playbutton {
  width: 64px;
  height: 64px;
  background-color: var(--clickable-white-link);
  border-radius: 64px;
  border: 1px solid rgba(255, 255, 255, 0);
  display: flex;
  transition: all 0.2s ease-in-out 0s;
  align-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 10px 1px rgba(255, 255, 255, 0);
  opacity: 0.8;
}

.portal .portal-header .portal-header-video-splash-playbutton .icon.play {
  display: flex;
  font-size: 1.25rem;
  padding: 1.55rem 0.5rem;
  color: var(--lg-blue-light);

  transition: all 0.7s ease-in-out 0s;
}

.portal
  .portal-header
  .portal-header-video-splash:hover
  .portal-header-video-splash-playbutton {
  border: 1px solid rgba(255, 255, 255, 1);
  box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.5);
  opacity: 1;
}

.portal
  .portal-header
  .portal-header-video-splash:hover
  .portal-header-video-splash-playbutton
  .icon.play {
  color: var(--lg-blue-dark);
}

@media (max-width: 980px) {
  .portal .portal-header .portal-header-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .portal .portal-header .portal-header-info {
    max-width: 550px;
    text-align: left;
    margin-bottom: 2rem;
  }

  .portal .portal-header-phone-label {
    display: none;
  }
}

@media (max-width: 600px) {
  .portal .portal-header .portal-header-video {
    width: 100%;
  }

  .portal .portal-header .portal-header-video-splash {
    width: auto;
    display: flex;
  }
}
