.availability-form .time-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.availability-form .time-selector > .selection.dropdown {
  width: auto;
  min-width: auto;
}
