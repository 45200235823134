.booking-resource-item.item {
  display: flex;
  height: 100%;
  flex-grow: 1;
  align-items: center;
  padding: 4px;
  background-color: rgba(255,255,255,1);
}

.booking-resource-item.item.active {
  background-color: rgba(0,0,0,0.1);
}

.booking-resource-item.item .info {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
