.guide-summary {
  display: flex;
  flex-direction: row;
  height: 180px;
}

.guide-summary .summary-avatar.ui.avatar.image {
  display: flex;
  height: 180px;
  width: 180px;
}

.guide-summary .summary-information {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 2rem;
}

.guide-summary .summary-readout {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.guide-summary .summary-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.guide-summary .summary-guidename {
  font-family: "Avenir Next Bold";
  font-size: 36px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 25.9px;
  letter-spacing: normal;
  color: #1c1c1c;
  padding: 0.5rem 0;
}

.guide-summary .summary-guidespecialty {
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 0.25rem;
}

.guide-summary .summary-guidespecialty-label {
  margin-right: 0.4rem;
  color: #717171;
}

.guide-summary .summary-guidespecialty-value {
  color: #000000;
}

.guide-summary .summary-secondary {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.guide-summary .summary-secondary .ui.button {
  display: flex;
  background-color: #f9f9f9;
  height: 36px;
}

.guide-summary .summary-badges {
  display: flex;
  flex-direction: row;
}

.guide-summary .summary-badges {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #e0e4f0;
}

/*Badge*/
.summary-badges .summary-badge {
  display: flex;
  flex-direction: row;
}

.summary-badges .summary-badge i.icon {
  font-size: 19px;
  color: #1e58eb;
}

.summary-badges .summary-badge-info {
  display: flex;
  flex-direction: column;
}

.summary-badges .summary-badge-info-label {
  color: #000;
  line-height: 1.23;
  font-size: 14px;
}

.summary-badges .summary-badge-info-value {
  color: #717171;
  font-size: 14px;
  line-height: 1.23;
  font-family: "Avenir Next";
}

/*Compressed view with scheduler*/
.guide-summary.is-compressed {
  height: 150px;
}

.guide-summary.is-compressed .summary-avatar.ui.avatar.image {
  height: 150px;
  width: 150px;
  min-width: 150px;
  min-height: 150px;
  display: flex;
  margin: 0;
}

.guide-summary.is-compressed .summary-information {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.guide-summary.is-compressed .summary-information .summary-badge {
  min-height: 2rem;
  display: flex;
  padding: 0;
  margin: 0 10px 0 0;
}

.guide-summary.is-compressed .summary-information .summary-readout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0;
}

.guide-summary.is-compressed .summary-information .summary-main {
  flex-grow: unset;
}

/* .guide-summary.is-compressed .summary-information .summary-secondary {
  justify-content: flex-start;
  flex-direction: row;
  padding: 0;
  margin: 0;
  flex-grow: unset;
} */

.guide-summary.is-compressed .summary-information .summary-secondary {
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 14px;
  display: flex;
  align-items: flex-end;
}

/* This selector was causing the guide video to not display */

/* .guide-summary.is-compressed .summary-information .summary-secondary {
  display: none;
} */

.guide-summary.is-compressed .summary-ctas {
  display: flex;
}

.guide-summary.is-compressed .summary-ctas .ui.button {
  display: flex;
  background-color: transparent;
  color: #1e58eb;
  padding: 0;
}

.guide-summary.is-compressed .summary-ctas .summary-ctas-button-icon {
  font-size: 20px;
  margin: 0 !important;
}

.guide-summary.is-compressed .summary-ctas .summary-ctas-button-text {
  font-family: "Avenir Next Bold";
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #1e58eb;
  margin-left: 3px;
}

.guide-summary.is-compressed .summary-information .summary-badges {
  display: flex;
  border-top: none;
  align-items: flex-start;
}

.guide-summary.is-compressed .summary-information .summary-badge {
}

.guide-summary.is-compressed .summary-information .summary-badge-info {
  padding: 0;
  margin: 0 0 0 3px;
}

.guide-summary.is-compressed .summary-information .summary-badge-info-label {
}

.guide-summary.is-compressed .summary-information .summary-badge-info-value {
}

@media only screen and (max-width: 970px) {
  .guide-summary.is-compressed .summary-information {
    flex-direction: column;
  }
  .guide-summary.is-compressed .summary-information {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .guide-summary,
  .guide-summary.is-compressed {
    flex-direction: column;
    height: auto;
    align-items: center;
  }
  .guide-summary.is-compressed .summary-avatar.ui.avatar.image,
  .guide-summary .summary-avatar.ui.avatar.image {
    /* height: 90px;
    width: 90px; */
  }

  .guide-summary.is-compressed .summary-information {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .guide-summary.is-compressed .summary-information .summary-secondary {
    justify-content: center;
  }
}
