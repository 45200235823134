.guide-dashboard .pending-calls-container {
  display: flex;
}

.guide-dashboard .requested-calls {
  flex: 1;
}

/* TODO: Move to call- entry list item?  */
.guide-dashboard
  .pending-calls-container
  .callentry-list-item
  .list-item-container {
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}
