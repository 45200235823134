.requested-time {
  /* width: 92px; */
  /* height: 22px; */
  font-family: "Avenir Next Medium";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5d657b;
  display: flex;
  flex-direction: column;
}

.requested-time-list-item {
  padding: 1em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 0.5px #dce5fd;
}

.requested-time-list-item:last-child {
  border-bottom: none;
}

.requested-time-list-item-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.requested-time-list-item-header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Avenir Next Medium";
  font-size: 16px;
  color: #5d657b;
}

.requested-time-list-item-header-subheader {
  font-size: 0.8rem;
  font-weight: 400;
  color: rgba(225, 225, 225, 0.9);
  display: flex;
}

.requested-time-list-item-time {
  display: flex;
  font-size: 1.3em;
  font-weight: normal;
  font-family: "Avenir Next Medium";
  font-size: 16px;
  color: #5d657b;
}

.requested-time-list-item-accept-call {
  display: flex;
}

.requested-time-list-item-accept-call-button {
  display: flex;
  width: 64px;
  height: 27px;
  justify-content: center;
  align-items: center;
  font-family: "Avenir Next Medium";
  font-size: 12px;
  line-height: 12px;
  padding: 0 !important;
  margin: 0;
}

.requested-time-reject-call {
  display: flex;
  justify-content: center;
  align-items: center;
}

.requested-time-reject-call .requested-time-reject-call-button.ui.basic.button {
  display: flex;
  width: 140px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border: solid 1px #dce5fd !important;
  box-shadow: none !important;
}
