.communcations-controlpanel,
.communcations-controlpanel
  .communcations-controlpanel-list.ui.horizontal.list {
  display: flex;
  color: #5d657b;
  font-size: 1rem !important;
  font-weight: 400 !important;
  font-family: "Avenir Next Medium";
  padding: 0;
  cursor: pointer;
}

.communcations-controlpanel
  .communcations-controlpanel-list.ui.horizontal.list
  .item {
  color: rgba(0, 0, 0, 0.4);
  transition: color 500ms, background 1s;
  text-decoration: none;
}

.communcations-controlpanel
  .communcations-controlpanel-list.ui.list.horizontal
  .item:hover {
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none !important;
}

@media screen and (max-width: 520px) {
  .communcations-controlpanel
    .communcations-controlpanel-list.ui.horizontal.list {
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  .communcations-controlpanel,
  .communcations-controlpanel
    .communcations-controlpanel-list.ui.horizontal.list {
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
  }
}
