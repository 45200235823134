.guide-card.ui.card {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 2em; */
  max-width: 300px;
  min-width: 280px;
  /* height: 380px; */
  min-height: 320px;
  max-height: 380px;
  border-radius: 8px;
  box-shadow: 0 1px 20px 0 rgba(202, 212, 237, 0.34);
  border: solid 1px #efefef;
  background-color: #ffffff;
}

@media (max-width: 320) {
  .guide-card.ui.card {
    background: red;
  }
}

a.ui.card.guide.guide-card:hover {
  box-shadow: 0 1px 20px 0 rgba(202, 212, 237, 0.7);
  border: solid 1px #efefef;
  background-color: #ffffff;
}

.guide-card.ui.card .content {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 1.6em;
}

.guide-card .guide-card-faceplate {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.guide-card .guide-card-faceplate .avatar.image {
  width: 10em;
  height: 10em;
  overflow: visible;
}

.guide-card .guide-card-faceplate .avatar img {
  border: 5px solid white;
  box-shadow: 0px 1px 6px 0px #d8d8d8;
}

.guide-card .guide-card-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-content: center;
  padding: 0.5em 1em 1.5em 1em;
}

.guide-card .guide-card-actions {
  display: flex;
  display: flex;
  margin: 1rem 1rem 0rem 1rem;
  justify-content: center;
  width: 100%;
}

.button.ui.fluid.primary.button.schedule-cta {
  font-size: 0.8rem;
  border-radius: 4px;
  border-radius: 3px;
  background-color: #1e58eb !important;
  color: #fff !important;
}

.guide-card .expert-subject-label {
  font-weight: bold;
  font-size: 0.8em;
  color: "#5d657b";
  /* padding: 0.5em 1.5em; */
}

.guide-card .guide-description {
  display: flex;
  align-items: flex-end;
  padding: 1rem;
  text-align: center;
}

.guide-card i.icon.calendar {
  padding-right: 20px;
}

.guide-card .guide-name {
  padding: 0.8rem 0.5rem 0.25rem 0.5rem;
  color: #3b404d;
  font-size: 1.6em;
}

.guide-card .guide-card-content {
  display: flex;
}

.guide-card .expert-subjects {
  text-align: center;
  flex-direction: column;
  display: flex;
  align-self: flex-end;
  width: 100%;
}

.guide-card .expert-subjects-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.guide-card .expert-subjects-container .expert-subject {
  display: flex;
  min-width: 30%;
  text-align: center;
  padding: 0.25rem;
  background-color: transparent;
  font-family: "Avenir Next";
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3b404d;
  flex-direction: column;
  justify-content: center;
}
