.mobile_menu_bottom {
  transition: all 0.5s ease-in-out;
}

.appnav-mobile {
  overflow: auto;
}

.mobile-sidebar-container > div:first-child {
  overflow-y: auto !important;
  position: static;
}

.mobile-sidebar-container > div > div:nth-child(3) {
  display: flex;
}

@media only screen and (min-width: 1400px) {
  .ui.container {
    width: unset;
  }
}

@media only screen and (max-width: 767px) {
  .ui.menu {
    border-radius: 0px;
  }

  .ui.steps.guide_registration_steps {
    flex-direction: row;
  }

  .ui.steps .step {
    width: initial !important;
  }
  .responsive_container_mobile_bottom .ui.vertical.menu .item {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  .responsive_container_mobile_bottom .ui.vertical.menu .item:first-child {
    border-top: none;
  }

  #mobile-content {
    padding-bottom: 40px;
  }

  .ui.menu .item {
    padding: 1.4em 1.4em;
  }

  .ui.top.right.pointing.dropdown > .menu {
    z-index: 999;
  }

  .ui.fluid.card {
    position: relative;
  }

  .ui.fluid.card:after {
    content: "";
    height: 75%;
    max-height: 75%;
    margin-top: 4vh;
    margin-right: -16px;
    width: 1px;
    position: absolute;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
    overflow: visible;
    visibility: visible;
  }
  .ui.fluid.card:last-child:after {
    content: "";
    height: 75%;
    margin-right: -16px;
    width: 1px;
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    overflow: hidden;
    visibility: hidden;
  }
}
